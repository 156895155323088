/* eslint no-console:0 */
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"



const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

$(document).on('turbolinks:load', function() {
  $(window).trigger('load.bs.select.data-api');
});