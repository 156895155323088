import { ApplicationController } from "../support/application-controller";
import safetext from "../support/safetext";

export default class extends ApplicationController {
  static targets = [
    "step",
    "required",
    "message",
    "progress",
    "initialValue",
    "currentValue",
    "basicInfo",
  ]; // jshint ignore:line

  step = 0;

  get currentStep() {
    return this.step;
  }

  set currentStep(value) {
    this.step += value;
  }

  connect() {
    this.progressTargets[1].style.backgroundColor = "#afc36c";
    if (this.hasStepTarget) {
      this.stepTargets[this.currentStep].setAttribute(
        "class",
        "user-pbl-form-visibility-on"
      );
      var find = 'button[data-slide-index="' + String(1) + '"]';
      var btn = $(find)[0];
      btn.classList.add("current");
    }

    if(window.location.hash) {
      this.jumpToStepFromLink();
    }
  }

  back(e) {
    e.preventDefault();
    const element = e.currentTarget;
    var index = parseInt(
      document.getElementById("btn-back").getAttribute("data-slide-index")
    );
    var counts = parseInt(
      document.getElementById("btn-back").getAttribute("total-data")
    );
    this.step_change(index, counts);
    if (index == 0) {
      this.disable_buttons(1);
    }
    var find = 'button[data-slide-index="' + String(index + 1) + '"]';
    var btn = $(find)[0];
    btn.classList.add("current");
  }

  next(e) {
    e.preventDefault();
    const element = e.currentTarget;
    var index = parseInt(
      document.getElementById("btn-next").getAttribute("data-slide-index")
    );
    var counts = parseInt(
      document.getElementById("btn-next").getAttribute("total-data")
    );
    this.step_change(index, counts);
    this.disable_buttons(2);
    var find = 'button[data-slide-index="' + String(index + 1) + '"]';
    var btn = $(find)[0];
    btn.classList.add("current");
  }

  //addFormData Appears to be dead code 1.16.23
  addFormData(e) {
    this.toggleSpinner(e);
    let value = this.currentValueTargets[this.currentStep - 1].value;
    let initialValue = this.initialValueTargets[this.currentStep - 1].value;
    if (value !== initialValue) {
      const [xhr, options] = e.detail;
      options.data = "pbl_value[value]=" + value;
      return;
    }
    e.preventDefault();
    this.toggleSpinner(e);
    this.next(e);
  }

  onPblValueSuccess(e) {
    this.toggleSpinner(e);
    const [response, status, xhr] = e.detail;
    this.initialValueTargets[this.currentStep - 1].value = response.value;
    this.next(e);
  }

  toggleSpinner(e) {
    var spinner =
      this.stepTargets[this.currentStep].getElementsByClassName("spinner");
    if (spinner[0].style.display === "inline") {
      spinner[0].style.display = "none";
    } else {
      spinner[0].style.display = "inline";
    }
  }

  showSpinner(e) {
    var spinner = document.getElementsByClassName("spinner");
    spinner[0].style.display = "block";
  }

  jumpToStepFromElement(e) {
    e.preventDefault();
    const element = e.currentTarget;
    var index = parseInt(element.getAttribute("data-slide-index"));
    var counts = parseInt(element.getAttribute("total-data")) + 1;
    this.step_change(index - 1, counts);
    this.disable_buttons(index);

    document
      .getElementById("btn-next")
      .setAttribute("total-data", String(counts));
    document
      .getElementById("btn-back")
      .setAttribute("total-data", String(counts));

    element.classList.add("current");
  }

  jumpToStepFromLink() {
    let hash = window.location.hash; //ex. path#1
    let index = parseInt(hash.replace("#", ""));

    var btn_next = document.getElementById("btn-next");
    var btn_back = document.getElementById("btn-back");
    var counts = parseInt(btn_next.getAttribute("total-data")) + 1;

    this.step_change(index - 1, counts);
    this.disable_buttons(index);

    btn_next.setAttribute("total-data", String(counts));
    btn_back.setAttribute("total-data", String(counts));

    var find = 'button[data-slide-index="' + String(index) + '"]';
    var btn = document.querySelector(find);

    if (btn) {
      btn.classList.add("current");
    }
  }

  step_change(index, counts) {
    var pbl_val_id = this.stepTargets[index].getAttribute("data-id");
    var user_id = this.stepTargets[index].getAttribute("user-id");

    $.ajax({
      type: "PATCH",
      url: "/lock_steps/" + pbl_val_id + "/" + user_id + "/" + user_pbl_id,
      dataType: "JSON",
      success: function (response) {
        var i;
        for (i = 0; i < counts - 1; i++) {
          //Updates locked icon for each tab
          var icon = document.getElementById("icon" + (i + 2));
          if (response[i].locked) {
            if (!icon.classList.contains("custom-color")) {
              icon.classList.remove("fa-check-circle");
              icon.classList.add("fa-user-lock");
            }
          } else {
            if (!icon.classList.contains("custom-color")) {
              icon.classList.remove("fa-user-lock");
              icon.classList.remove("fa-user-lock");
              icon.classList.add("fa-check-circle");
            }
          }
        }

        $.each(response, function (i, v) {
          if (v.id == pbl_val_id) {
            if (v.locked) {
              if (v.value != "") {
                $("#summernote" + index).summernote("code", v.value);
              }
              if (v.locked_by != user_id) {
                $("#summernote" + index).summernote("disable");
              } else {
                $("#summernote" + index).summernote("enable");
              }
            }
          }
        });
      },
    });

    this.hideAll(counts);
    this.stepTargets[index].setAttribute(
      "class",
      "user-pbl-form-visibility-on"
    );
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    document
      .getElementById("btn-next")
      .setAttribute("data-slide-index", String(index + 1));
    document
      .getElementById("btn-back")
      .setAttribute("data-slide-index", String(index - 1));
    this.clear_background(counts);
    this.progressTargets[index].style.backgroundColor = "#afc36c";

    // check to see if the user hasn't seen a comment on this step, if so remove it
    $.ajax({
      type: "PATCH",
      url: "/comments_seen/" + pbl_val_id + "/" + user_id,
      dataType: "JSON",
      success: function (response) {
        console.log(response);
      },
    });
  }

  clear_background(counts) {
    for (var i = 0; i < counts; i++) {
      this.progressTargets[i].style.backgroundColor = null;
    }
  }

  disable_buttons(index) {
    if (index == 1000) {
      var but = document.getElementById("aside").getElementsByTagName("button"),
        index;
      for (index = but.length - 1; index >= 0; index--) {
        but[index].disabled = true;
      }
    } else {
      var but = document.getElementById("aside").getElementsByTagName("button"),
        index;
      for (index = but.length - 1; index >= 0; index--) {
        but[index].disabled = false;
      }
    }
  }

  hideAll(counts) {
    this.stepTargets[0].setAttribute("class", "user-pbl-form-visibility-off");
    for (var i = 1; i < counts + 1; i++) {
      this.stepTargets[i].setAttribute("class", "user-pbl-form-visibility-off");
    }
    this.remove_highlights();
  }

  remove_highlights() {
    var el = document.getElementsByClassName("current");
    while (el[0]) {
      el[0].classList.remove("current");
    }
  }
}
