import { ApplicationController } from "../support/application-controller";
import safetext from "../support/safetext";

export default class extends ApplicationController {
  static targets = ['dataBox'] // jshint ignore:line
  toggleVisibility(e) {
    e.preventDefault();
    console.log(this.dataBoxTarget.style.display);
    if (this.dataBoxTarget.style.display === "block") {
      this.dataBoxTarget.style.display = "none";
    } else {
      this.dataBoxTarget.style.display = "block";
    }
  }
}
