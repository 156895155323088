import { ApplicationController } from "../support/application-controller";
import safetext from "../support/safetext";

export default class extends ApplicationController {
  static targets = ['newComment', 'commentList', 'messageCount'] // jshint ignore:line

// Toggles the comment list's visibility
  toggle(e) {
    e.preventDefault()
    let element = e.target.nextElementSibling
    if (element.style.display === 'block') {
      element.style.display = 'none'
    } else {
      element.style.display = 'block'
    }
  }

// Add the text from textarea to the XHR request before sending
  addComment(event) {
    const formData = new FormData();
    formData.append('user_pbl_comment[comment]', this.newComment)
    event.detail[1].data = formData
    this.messageCount = (parseInt(this.messageCount) + 1) + " Comments";
  }

// Update comment list and clear textarea
  onPostSuccess(event) {
    let [data, status, xhr ] = event.detail;
    this.commentListTarget.innerHTML += xhr.response;
    this.newComment = "";
  }

// GETTERS and SETTERS

  get newComment() {
    return safetext(this.newCommentTarget.value);
  }

  set newComment(text) {
    this.newCommentTarget.value = text;
  }

  get messageCount() {
    return safetext(this.messageCountTarget.innerHTML);
  }

  set messageCount(text) {
    this.messageCountTarget.innerHTML = text;
  }
}
