import { ApplicationController } from "../support/application-controller";
import safetext from "../support/safetext";


const LEAVING_PAGE_MESSAGE = "Are you sure you want to leave this page? Any unsaved changes will be lost."
export default class extends ApplicationController {

  connect() {
    window.addEventListener("beforeunload", this.leavingPage)
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.leavingPage)
  }

  leavingPage(event) {
    if (event.type == "turbolinks:before-visit") {
      if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
        event.preventDefault()
      }
    } else {
      event.returnValue = LEAVING_PAGE_MESSAGE;
      return event.returnValue;
    }

  }
}
